@mixin populate-checkboxes($list) {
  @each $name, $color, $border-color in $list {
    .checkbox-color--#{$name} {
      --checkbox-background-color: #{$color};
      --checkbox-border-color: #{$border-color};

      .fuze-toggle-element__input:checked + .fuze-toggle-element__content:before {
        background-color: #{$color};
        border-color: $border-color;
      }
    }
  }
}

@mixin populate-chart-paths($list) {
  @each $name, $color in $list {
    .recharts-line.#{$name} path,
    .recharts-line.#{$name} .recharts-dot {
      stroke: #{$color};
    }
    .recharts-sector.#{$name} {
      fill: #{$color};
      stroke: white;

      &:hover {
        stroke: #{$color};
      }
    }
    .recharts-bar.#{$name} path {
      fill: #{$color};
    }
  }
}
