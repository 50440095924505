.suggested-dids {
  --columns: 3;

  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(var(--columns), 1fr);

  @include media-breakpoint-down(md) {
    --columns: 2;
  }

  & > label {
    max-width: 100%;
  }

  .card {
    line-height: 1.5;
    padding: 0.75rem;
    padding-left: 3rem;
    text-align: left;

    &:before,
    &::after {
      top: 50% !important;
    }

    &:before {
      margin-top: -12px;
    }

    &::after {
      margin-top: -6px !important;
    }
  }

  #did-other-option__radio-radio-field {
    grid-column-start: 1;
    grid-column-end: var(--columns);
    margin: 1rem 1.125rem;
  }
}

.suggested-dids {
  margin: 1rem;

  & + .did-search {
    background-color: $gray-3;
    padding: 2rem;
    margin: 2rem 3rem 0;

    #did-other-selector__select + label {
      margin: 1rem;
    }

    .location {
      display: none;
    }
  }
}

.suggested-did-option__location {
  color: $gray-50;
  font-weight: 400;
  font-size: 13px;
}

.did-search {
  .location {
    color: $gray-500;
    font-size: 0.9em;
    font-weight: 400;
  }
  .portStatusDescription {
    padding: 1rem;
    text-align: left;
    margin-top: 0.5rem;
    .portStatusDescription__text {
      margin-left: 0.125rem;
      line-height: 1.375rem;
      vertical-align: top;
    }
  }
}

.did-search__select-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .select-country {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;

    .select-country {
      flex: 0 10rem;
      margin: 0;

      & > .fuze-select__control {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .select-did {
      border-radius: 0;
      flex: 1;
      margin-left: -1px;
      .did-status {
        margin-right: 5px;
        opacity: 0.5;
        color: $fuze-gray-50;
        font-style: italic;
      }

      & > .fuze-select__control {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

.no-dids-available {
  background-color: $gray-050;
  padding: 1rem;
  border-radius: 4px;
  max-width: 34rem;
  margin-bottom: 1.5rem;
}

.no-dids-available__title {
  font-weight: bold;
}

.no-dids-available__description {
  font-style: italic;
}
