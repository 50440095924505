// For icon font (non-SVG) icons
.icon-circled {
  margin-right: 0.313rem;
  border-radius: 100%;
  font-size: $fuze-font-size-regular;
  padding: 0.188rem;
  bottom: 0;
  width: 13px;
}

.icon-circled--green {
  color: white;
  background-color: $fuze-green;
}

.icon-circled--red {
  color: white;
  background-color: $fuze-red;
}

.icon-circled--red-inverse {
  color: $fuze-red;
  background-color: white;
}

.icon-circled--purple {
  color: white;
  background-color: $fuze-purple-primary;
}

.icon-circled--orange {
  color: white;
  background-color: $fuze-orange-yellow-30;
}

.icon-circled--gray {
  color: white;
  background-color: $gray-20;
}

////

.icon--tiny {
  // 24px
  height: 0.81rem;
  width: 0.81rem;
}

.icon--small {
  // 24px
  height: 1.5rem;
  width: 1.5rem;
}

.icon--medium {
  // 33px
  height: 2.063rem;
  width: 2.063rem;
}

.icon--tall {
  // 30px H x 20px W
  height: 1.875rem;
  width: 1.25rem;
}

.icon--greyCircleWrapper {
  background-color: $gray-5;
  padding: 0.625rem;
  border-radius: 50%;
  display: inline-block;
}

.icon--buildingWrapper {
  composes: greyCircleWrapper;
  padding: 0.625rem 0.875rem 0.688rem 1rem;
}

.icon--opaque path {
  opacity: 1;
}

.icon--black path {
  fill: $gray-99;
}

.icon--grey60 path {
  fill: $gray-60;
}

.icon--purple path {
  fill: $purple-light;
}

.icon--orange path {
  fill: $orange-light;
}

.icon--green path {
  fill: $green;
}

.icon--red path {
  fill: $red;
}

.icon--teal path {
  fill: $teal;
}

.icon--purpleGroup g {
  fill: $purple;
}

.icon--whiteGroup g {
  fill: white;
}

.icon--purpleGroupStroke g {
  stroke: $purple;
}
