.font-size-base {
  font-size: $font-size-base;
}
.font-size-xl {
  font-size: $font-size-xl;
}
.font-size-lg {
  font-size: $font-size-lg;
}
.font-size-sm {
  font-size: $font-size-sm;
}
.font-size-xs {
  font-size: $font-size-xs;
}
.font-size-xxs {
  font-size: $font-size-xxs;
}
.font-size-xxxs {
  font-size: $font-size-xxxs;
}
