.back-button {
  min-width: 16.25rem !important;
  margin-right: 1rem;

  // This negates the above.
  // For backbutton however, this is too generic of a solution.  It should
  // enforce a markup structure, and conditionally give margin to siblings
  // that follow.
  .modal-footer & {
    margin: 0;
  }
}

.back-button--not-wide {
  min-width: 7.8rem !important; // overwrite the new button design for the old back button
}
