.dashboard-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 33rem;
  padding: 2rem 0;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    max-width: 100%;
  }
}

.dashboard-action {
  align-items: center;
  flex: 1 1 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  margin: 2rem;

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    text-align: center;
    max-width: 250px;
    margin: 2.75rem 1rem;
  }
}

.dashboard-action__img {
  height: 115px;
  margin-right: 2rem;
  @include media-breakpoint-up(lg) {
    margin-right: initial;
    margin-bottom: 1.5rem;
  }
}

.dashboard-action__title {
  font-size: $fuze-font-size-large;
  font-weight: $fuze-font-weight-light;
  margin-bottom: 1rem;
  .dashboard-action__small & {
    font-size: initial;
    font-weight: $fuze-font-weight-regular;
    margin-bottom: 0.5rem;
  }
}

.dashboard-action__description {
  line-height: $fuze-font-size-h2-sub;
  @include media-breakpoint-up(lg) {
    min-height: 60px;
  }
  .dashboard-action__small & {
    margin-bottom: 2rem;
  }
}

.dashboard-action__link {
  padding: 0.65rem 0;
  max-width: 250px;
  width: 100%;
}

.dashboard-action__icon-phone {
  font-size: 1.188rem;
}

.dashboard-action__icon {
  margin-right: 0.5rem;
  bottom: inherit;
  vertical-align: bottom;
  &:before {
    color: inherit;
  }
}
