@mixin button--transparent {
  background: none;
  border: 0;
  padding: 0;
}

/// COMPLEMENTARY GRADIENT COLORS(BLUE/TEAL)
$fuze-complement-start: #6b7bdb;
$fuze-complement-middle: #4598d7;

@mixin white-text {
  color: $white;
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: $white;
  }
}

@mixin gradient-dark-purple-to-extra-light-purple($angle: 45deg) {
  background-color: $purple-extra-dark;
  background: linear-gradient($angle, $purple-extra-dark, $purple-extra-light);
}

@mixin gradient-purple-to-extra-light-purple($angle: 45deg) {
  background-color: $purple;
  background: linear-gradient($angle, $purple, $purple-extra-light);
}

@mixin gradient-dark-purple-to-light-purple($angle: 45deg) {
  background-color: $purple-dark;
  background: linear-gradient($angle, $purple-dark, $purple-light);
}

@mixin gradient-dark-purple-to-purple($angle: 45deg) {
  background-color: $purple-extra-dark;
  background: linear-gradient($angle, $purple-extra-dark, $purple);
}

@mixin gradient-blue-to-teal($angle: 45deg) {
  background-color: $blue;
  background: linear-gradient($angle, $blue, $teal);
}

@mixin gradient-purple-blue-to-teal($angle: 240deg) {
  background-image: linear-gradient(240deg, $teal, $fuze-complement-middle 51%, $fuze-complement-start);
}

@mixin gradient-purple-purple-to-blue($angle: 240deg) {
  background-image: linear-gradient($angle, $fuze-complement-middle, $blue 51%, $fuze-purple-primary);
}

@mixin gradient-dark-teal-to-teal($angle: 45deg) {
  background-color: $teal-dark;
  background: linear-gradient($angle, $teal-dark, $teal);
}

$custom-gradient-color1: #4598d7;
$custom-gradient-color2: #6b7bdb;
$custom-gradient-color3: #744cbb;

@mixin custom-gradient($angle: 224.54deg) {
  background-color: $blue;
  background: linear-gradient(
    224.54deg,
    $custom-gradient-color1 0%,
    $custom-gradient-color2 50.62%,
    $custom-gradient-color3 100%
  );
}

@mixin fuze-warning-gradient($start: $orange, $end: $red) {
  background-color: rgba($start, 0.7); // Old browsers
  background-image: linear-gradient(45deg, rgba($start, 0.7) 0%, rgba($end, 0.7) 100%);
}

@mixin fuze-error-gradient($start: $red, $middle: $fuze-orange-red, $end: $orange-dark) {
  background-color: rgba($start, 0.7); // Old browsers
  background: linear-gradient(45deg, $start 0%, $middle 50.14%, $end 100%);
}

@mixin interactive-table-row {
  background-color: transparent;
  box-shadow: 0 0.063rem 0.625rem 0 rgba($black, 0.1);
  cursor: pointer;

  td,
  + tr td {
    border-top: 0.063rem solid $gray-5;
  }
}

// Removes animations for users who have reduced motion enabled in their OS
@mixin optional-reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    // !important is needed to override inline animation styles
    transition: none !important;
    animation: none !important;
  }
}
