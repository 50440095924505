@keyframes spinner-spin-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
