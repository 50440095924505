//  Project-wide variables
//  If a variable is used in more than one file, then it belongs here.
//  Otherwise, keep the variable local

//  section-block
//  ---------------------------------------------------------------------------
$sectionBlockPadding: 2rem;
$sectionBlockPaddingLeft: $sectionBlockPadding;
$sectionBlockPaddingRight: $sectionBlockPadding;
$sectionBlockPaddingHorizontal-sm: 1rem;
$sectionBlockPaddingTop: 1.6875rem;
$sectionBlockPaddingBottom: 2rem;

//  forms
//  ---------------------------------------------------------------------------
$fixed-width-field-short: 17.5rem;
$fixed-width-field-long: 32rem;

//  icons
//  ---------------------------------------------------------------------------
$icomoon-selector: '&>[class^="icon-"], &>[class*=" icon-"]';
