.error-cover-view__top-bar {
  background-color: $purple-dark;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 1rem;

  img {
    height: 3rem;
  }
}

.error-cover-view {
  align-items: center;
  background-color: $purple-dark;
  color: white;
  display: flex;
  flex: 1;
  font-weight: 400;
  justify-content: center;

  img {
    background: $red;
    border: 3px solid $red;
    border-radius: 100%;
    margin-bottom: 1.5rem;
    overflow: hidden;
  }
}

.error-cover-view__inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  text-align: center;
  width: 100;
}

.error-cover-view__title {
  font-size: 2rem;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.error-cover-view__action {
  margin-top: 1rem;
}

.error-cover-view__more-info {
  font-size: 13px;
}
