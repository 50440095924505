$itemPaddingSides: 3.5rem;
$itemPaddingLeft: $itemPaddingSides + $sectionBlockPaddingLeft;
$itemPaddingRight: $sectionBlockPaddingRight;

$itemPaddingTopAndBottom: 1rem;
$itemPaddingTop: $itemPaddingTopAndBottom;
$itemPaddingBottom: $itemPaddingTopAndBottom;

$instructionBodyPadding: 3rem;

.instructions-header {
  color: $gray-80;
  font-weight: 500; // $font-weight-md
  margin-bottom: 2.5rem;
}

.instructions {
  counter-reset: count;
  list-style: none outside none;
  padding: 0;

  .instruction-buttons,
  .footer {
    padding-left: $sectionBlockPaddingLeft + $instructionBodyPadding;
    padding-right: $sectionBlockPaddingRight + $instructionBodyPadding;
    padding-top: $sectionBlockPadding;
    border-top: 1px solid $gray-10;
  }
}

.instruction {
  $size: 2.3rem;

  line-height: 1.4;
  list-style-position: inside;
  margin-bottom: 2.5rem;
  position: relative;

  &:not(.skip-numbering) {
    counter-increment: count;

    .instruction-header {
      align-items: center;
      background-color: $gray-3;
      display: grid;
      font-size: $font-size-xl;
      font-weight: $fuze-font-weight-light;
      grid-template-columns: 4.5rem 1fr;
      margin-bottom: 2rem;
      padding: 1rem 0.5rem;

      &::before {
        align-items: center;
        border-radius: 50%;
        box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.125);
        display: flex;
        content: counter(count);
        font-size: $font-size-xl;
        font-weight: $font-weight-bold;
        justify-content: center;
        height: $size;
        margin: auto;
        width: $size;
        -ms-flex: 0 0 $size;
        margin-right: 1rem; // Needed for IE11
        margin-left: 1rem; // Needed for IE11
      }
    }
    .instruction-title-block {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      -ms-flex: 1 1 auto;
    }
    .instruction-title {
      margin-right: 1rem;
    }
    .instruction-body {
      padding: 0 $instructionBodyPadding;
    }
    .instruction-subtitle {
      font-size: $font-size-base;
      color: $gray-600;
      padding: 0.5rem 0;
    }
  }

  &.complete {
    //  NOTE:
    //  because the background image is a circle, and it needs to reside within a circle,
    //  the background is re-sized to be bigger than its parent so that you cannot see
    //  a sliver of white around the green background.

    //  TODO: ENHANCEMENT
    //  The confirmation icon should not include the background color
    .instruction-header::before {
      content: "";
      background: url("../images/confirmation_icon.svg") no-repeat 50% transparent;
      background-size: 102%;
    }
  }

  &.error {
    .instruction-header::before {
      background-color: $red;
      color: white;
    }
  }

  p {
    color: $gray-60;
  }
}
