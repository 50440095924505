// This pattern has not been approved by UX
// For now, we are only using it internally for Alloy Migration Plan
// If it is used more widely in the future, then we can move it into hub-style & ui_components

.accordion-card {
  padding: 0.7rem 1rem 1rem 1rem;
  box-shadow: $fuze-card-box-shadow;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: 1rem;
}

.accordion-card__header {
  display: flex;
  justify-content: space-between;
}

.accordion-card__button {
  margin-right: 0.5rem;
}

.accordion-card__content {
  margin-top: 1rem;
}

.accordion-card__header-left {
  display: flex;
  align-items: center;
}
