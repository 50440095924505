.toggle-section {
  margin: 1rem;
}

.toggle-section + .toggle-section {
  border-top: 1px solid $gray-10;
  margin-top: 1em;
  padding-top: 1em;
}

// increases specificity for the benefit of box-shadow override
.btn.toggle-section__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: none;
  padding-right: 1.1rem;
}

.toggle-section__button__title {
  color: $gray-40;
  font-size: $fuze-font-size-small;
  font-weight: $font-weight-bold;
}
.toggle-section__button__arrow {
  display: flex;
  align-self: self-start;
}
.toggle-section__button__arrow i {
  color: $gray-50;
  font-size: $fuze-font-size-small;
  font-weight: $font-weight-bolder;
}

.toggle-section--body {
  padding: 0 0.5rem 0.5rem 1.5rem;
}
