.sortable-button {
  background: transparent;
  border: 0 none;
  padding: 0;
}

.icon-sort {
  &::before {
    color: $teal;
    padding-left: 0.313rem;
  }
}
.text-link {
  color: $teal;
}
