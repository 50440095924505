/*
 * Load order in this file is very important.
 * The more a selector or a variable is general, the higher
 * so that the CSS below it can inherit them and override them if needed
 *
 * ORDER:
 * 1. Libraries
 * 2. Project base
 * 3. UI Components
 * 4. Content Components
 * 5. Library overrides
 */

//  LIBRARIES
@import "react-datepicker/src/stylesheets/datepicker";
@import "react-toastify/scss/main";
@import "@fuze/icon-font/src/scss/icon-font";
@import "react-datepicker/dist/react-datepicker.css";

@import "./variables";
@import "./extensions";
@import "./mixins";
@import "@fuze/fuzzy/scss/fuzzy";

//  PROJECT BASE
@import "./element";
@import "./utility";
@import "./form";
@import "./cursor";
@import "./fonts";
@import "./buttons";
@import "./gradients";
@import "../components/layouts/BaseLayout";
@import "../components/layouts/components/ErrorCover";
@import "../components/layouts/components/HubSidebar";
@import "../components/Avatar-mixins";

//  UI COMPONENTS
@import "../ui_components/styles";
@import "../components/Charts/Charts";
@import "../DIDs/PortRequests/components/common/PortinDatePicker";
@import "../components/Image/Image";
@import "../components/Instructions";

//  CONTENT COMPONENTS
@import "../components/layouts/components/Header";
@import "../components/CellWithHeader/CellWithHeader";

@import "../components/DIDSelector/DIDSelector";
@import "../components/templates/ChartColumns";
@import "../components/templates/GradientSection";
@import "../components/support/SupportWarningBanner";
@import "../components/Avatar";
@import "../components/Dropdown";
@import "../components/UserDetails";
@import "../components/forms/Select";
@import "../components/AuthenticationUnauthorized/AuthenticationUnauthorized";
@import "../components/Table/BillingTable";
@import "../components/Table/EventCell";
@import "../components/Table/tableFormatters";
@import "../components/DashboardActions/DashboardActions";
@import "../components/buttons/InfoButton";
@import "../components/GrayBox";
@import "../components/Box/Box";
@import "../components/Box/BoxList";
@import "../components/Outline";
@import "../components/buttons/ActionWithBackButton.scss";
@import "../components/forms/FormFieldValidation.scss";
@import "../components/DashboardStatistics/DashboardStatisticsHeader";
@import "../components/accordion/accordion";
@import "../components/icon/icon";
@import "../components/HeaderFormatter";
@import "../components/ErrorBadge/ErrorBadge";
@import "../components/layouts/TopNavBar/components/_TopNavBar";
@import "../components/DatePicker/DatePicker";

//  LIBRARY OVERRIDES
@import "./hub-style";
@import "./toast";

// Top navigation bar
@import "../components/layouts/TopNavBar/TopNavBar";

// Things to axe start
// Things to axe end

[data-before-content]::before {
  content: attr(data-before-content);
  position: relative;
  z-index: 1;
}

[data-after-content]::after {
  content: attr(data-after-content);
  position: relative;
  z-index: 1;
}

.text-spacing {
  & > * {
    margin: 0.25rem 0;
    line-height: initial;
  }
}

.pagination {
  [class*="page-"].disabled {
    display: none;
  }

  .page-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .page-spacer {
    cursor: default;
    pointer-events: none;
  }
}

.table--with-borders {
  border: solid 1px $gray-10;
}

.gray-70 {
  color: $gray-70;
}

.react-tabs__tab {
  text-transform: uppercase;
}

.panel__list-item {
  i + i,
  i + span,
  i + strong,
  span + i,
  span + span,
  span + strong,
  strong + i,
  strong + span,
  strong + strong {
    margin-left: 1ch;
  }

  .second-line {
    display: block;
    color: $gray-70;
    line-height: $fuze-font-size-base;
    font-size: $fuze-font-size-small;
    margin-left: 0;

    span {
      margin-left: 0;
    }
  }
}
