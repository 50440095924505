@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/colors";

.processingIcon {
  vertical-align: top;
  color: $fuze-gray-3;
  background: $fuze-teal-primary;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 14px;
  padding: 1px 5px;
}

.successIcon {
  vertical-align: top;
  color: $fuze-gray-3;
  background: $fuze-green;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 14px;
  padding: 1px;
}

.failureIcon {
  vertical-align: top;
  color: $fuze-gray-3;
  background: $fuze-red;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 14px;
  padding: 1px;
}
