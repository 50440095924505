.react-datepicker-popper {
  .react-datepicker {
    width: 300px;
    font-family: $font-family-sans-serif;
    font-size: 14px;

    .fuze-date-picker--show-time-select-only & {
      width: initial;

      .react-datepicker__header {
        display: none;
      }
    }

    .react-datepicker__header {
      background-color: white;
      border: none;
      .react-datepicker__current-month {
        font-weight: 400;
        padding-bottom: 0.5rem;
        color: $gray-80;
      }
      .react-datepicker__day-names {
        width: 2rem;
        margin: 0 0.125rem;
        .react-datepicker__day-name {
          color: $gray-80;
        }
      }
    }
    .react-datepicker__month-container {
      float: none;
      padding: 10px 20px 0 20px !important;
      width: 100%;
      .react-datepicker__month {
        margin: 0.4rem 0.4rem 0.4rem 0;
        .react-datepicker__day {
          width: 2rem;
          height: 2rem;
          position: relative;
          margin: 0.25rem 0.125rem;
          padding-top: 0.125rem;
          font-weight: 300;
          &.react-datepicker__day--today {
            &::before {
              content: "";
              position: absolute;
              display: inline-block;
              left: 42%;
              top: 80%;
              width: 4px;
              height: 4px;
              background-color: $purple-light;
              border-radius: 4px;
            }
          }
          &.react-datepicker__day--selected {
            background-color: $purple-light;
            border-radius: 1rem;
          }
          &.react-datepicker__day--keyboard-selected {
            background-color: initial;
            color: black;
          }
          &.react-datepicker__day--disabled {
            color: $gray-80;
            opacity: 0.2;
          }
          &:hover {
            border-radius: 1rem;
          }
        }
      }
    }
    .cancel {
      color: $gray-40 !important;
    }
    .footer {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 1rem;

      .btn#{$fuze-bootstrap-button-selector}.btn-xs {
        padding: 5px;
        font-size: $font-size-xs;
      }
      .btn {
        height: 40px;
        min-width: 0;
        font-size: $font-size-xs;
        &.reschedule {
          margin-left: 15px;
          background-color: $teal;
          &.disabled {
            opacity: 0.5;
            &:hover {
              background-color: $teal;
            }
          }
        }
        &.cancel {
          color: $gray-40;
          background-color: white;
          border: 1px solid $gray-40;
        }
      }
    }
    .react-datepicker__navigation--next {
      margin-top: 0.5rem;
    }
  }
}

#section-supported-versions {
  .react-datepicker-wrapper {
    .main-form-schedule {
      position: relative;
      height: 40px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      text-transform: initial;
      background-color: initial;
      border: 1px solid rgba(255, 255, 255, 0.1);
      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: black;
        opacity: 0.1;
      }
    }
  }
}

.DatePicker {
  display: inline-block;
}

.fuze-date-picker .react-datepicker__input-container input {
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid $gray-40;
  padding: 0.5rem 0.625rem;
  color: $gray-80;
  width: 100%;
}

.fuze-date-picker {
  &.fuze-date-picker--show-time-select-only {
    margin: 10px;

    .react-datepicker-popper {
      margin: 0px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }
}

li.react-datepicker__time-list-item--selected {
  background-color: $gray-800 !important;
  font-weight: initial !important;
}

.time-picker-seperator {
  flex: none !important;
}
