.outline {
  padding: 0.75rem;
  border-radius: 2px;
  box-shadow: 0px 0px 4px 0px $fuze-gray-60;
}

.outline-close {
  color: $fuze-gray-50;
  font-size: 1rem;
  vertical-align: text-top;
}
