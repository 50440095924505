.box {
  box-shadow: 0 0 4px 0 rgba(black, 0.25);
  margin-bottom: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.box-content {
  padding: 1.5rem 3rem;
  &.gray {
    background-color: $gray-5;
  }
}
