.fuze-select {
  .fuze-select__control {
    box-shadow: none;
    border-color: $gray-500;
    min-height: 40px;

    &:hover {
      border-color: $teal;
    }

    &--is-focused {
      border-color: $purple-light;
    }
  }

  .fuze-select__control--is-disabled {
    background-color: $gray-5;
    border-color: $gray-5;
    color: rgba($gray-80, 0.5);
    font-style: italic;
  }

  .fuze-select__menu {
    z-index: 2;
  }

  .fuze-select__single-value {
    color: $gray-80;
    font-size: $font-size-base;
    width: 100%;
  }

  .fuze-select__placeholder {
    color: $gray-40;
  }

  .fuze-select__indicator-separator {
    width: 0;
  }

  .fuze-select__dropdown-indicator {
    color: $teal-dark;
  }

  .fuze-select__option {
    color: $gray-800;

    &.fuze-select__option--is-selected {
      background: $gray-050;
    }
  }

  .fuze-select__group-heading {
    color: $gray-990;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .fuze-select__multi-value {
    border-radius: 3.125rem;
  }

  .fuze-select__multi-value__label {
    padding-left: 0.75rem;
    background-color: $teal-dark;
    color: white;
    border-top-left-radius: 0.781rem;
    border-bottom-left-radius: 0.781rem;
  }

  .fuze-select__multi-value__remove {
    padding-right: 0.625rem;
    background-color: $teal-dark;
    color: white;
    border-radius: 0 0.781rem 0.781rem 0;
    margin-left: -1px;
  }

  .fuze-select__clear-indicator {
    border-radius: 50%;
    background-color: $gray-50;
    padding: 1px;
    margin-right: 0.625rem;

    svg {
      height: 0.813rem;
      width: 0.813rem;
      fill: $gray-10;
    }
  }
  .fuze-select__value-container--is-multi + .fuze-select__indicators {
    .fuze-select__dropdown-indicator {
      border-left: 1px solid $gray-200;
    }
  }

  .fuze-select__value-container {
    font-weight: 400;
  }
}

.fuze-select__option--is-focused {
  background: $gray-3 !important;
}

.fuze-select__option--is-selected {
  background: $gray-70 !important;
  span {
    color: white !important;
  }
}

.fuze-select-editable-input {
  .fuze-select__value-container > div {
    width: 100%;
  }
  .fuze-select__input {
    display: block !important;
  }
  input {
    opacity: 1 !important;
    width: 100% !important;
  }
}
