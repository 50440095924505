.support-warning-banner {
  border-radius: 4px;
  background: $gray-050;
  padding: 1rem;

  .title {
    color: $gray-700;
    font-size: $h4-font-size;
  }

  .description {
    color: $gray-400;
    font-style: italic;
    margin: 0;
  }

  i {
    opacity: 0.5;
  }

  .image-component {
    line-height: initial;
  }
}
