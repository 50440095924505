.chart-columns {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.chart-column {
  border: 1px solid transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 226px;

  & + .chart-column {
    border-left-color: $gray-050;
  }
}

.chart-column__title {
  font-size: 0.86rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.chart-column__content {
  height: 100%;
  position: relative;
  width: 100%;
}
