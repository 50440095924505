.overlay {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
}

.overlay--authentication-unauthorized {
  align-items: center;
  pointer-events: none;
  background: white;
  display: none;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s;
  user-select: none;
  z-index: 99999999999;

  .session--unauthorized & {
    display: flex;
    pointer-events: initial;
  }

  .is-logging-out & {
    display: none;
  }
}

.session--unauthorized > iframe {
  display: none;
}
