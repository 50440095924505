.user-details {
  display: flex;
}

.user-details__username {
  color: $gray-70;
}

.user-details--disabled {
  .avatar,
  .user-details__username {
    opacity: 0.5;
  }
  .subHeading {
    opacity: 0.7;
  }
}
