@import "node_modules/@fuze/bootstrap-theme/scss/bootstrap-variables/bootstrap-variables";
@import "./keyframes";

// The default spinner (e.g. <Spinner />):
//   spins clockwise,
//   is "inline",
//   is about the same size as text (e.g. replacing a button's text with a spinner)
// variables
$size: 1rem;

.spinner {
  // variables
  $spin: spinner-spin-right 1.1s infinite linear;
  $fadein: spinner-fade-in 0.5s ease-in 0.5s forwards;
  $fadeout: spinner-fade-out 0.5s ease-in forwards;

  // styling
  border: ($size/10) solid $gray-10;
  border-left-color: $gray-5;
  border-radius: 1000px;
  display: inline-block;
  margin: -0.2rem 0.1rem 0 0.1rem; // -0.2rem = nudge up just to align with small, inline text
  vertical-align: middle;
  // size
  height: $size;
  width: $size;

  animation: $spin;
  /**/
  &.page-spinner {
    /*
            The page-level spinner:
                is centered on the screen,
                is larger,
                fades in, (e.g. <Spinner classNames="spinner-page fade-in" />)
                then fades out (e.g. <Spinner classNames="spinner-page fade-out" />)
        */
    $size: 6rem;
    // styling
    border-width: ($size/10);
    left: 50%;
    margin: (-$size/2) 0 0 (-$size/2);
    opacity: 0;
    position: fixed;
    top: 50%;
    z-index: 3; // to override sticky table action buttons
    // size
    height: $size;
    width: $size;
    transition: opacity 0.5s linear 0.5s;
    &.fade-in {
      opacity: 1;
      transition-delay: 0s;
    }
  }
}

.btn .spinner {
  border: ($size/10) solid currentColor;
  border-left-color: transparent;
}
