.billingTable {
  margin-top: 0.5rem;
  margin-bottom: 2rem;

  .impactCell {
    vertical-align: middle;
  }
  .impactCell,
  .impactHeader {
    text-align: right;
    width: 15%;

    @include media-breakpoint-up(xl) {
      padding-right: 6rem;
    }
  }

  .eventsHeader {
    width: 30%;
  }

  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;

    thead {
      display: none;
    }

    tbody {
      display: block;
      width: 100%;

      tr {
        display: block;
        width: 100%;
        position: relative;
        border-bottom: 1px solid $gray-10;

        &:first-child {
          border-top: 1px solid $gray-10;
        }

        &:last-child td {
          border-bottom: 0 none;
        }

        &.interactive {
          &:hover {
            td,
            + tr td {
              border-top: 0 none;
            }
          }
        }
      }

      td {
        display: block;
        border-top: 0 none;
        width: 100%;
        padding: 0 0.25rem;

        &:first-child {
          padding-top: 0.75rem;
        }

        &:last-child {
          padding-bottom: 0.75rem;
        }
      }
    }

    .eventsHeader {
      width: 100%;
    }

    .impactCell {
      position: absolute;
      top: 0.75rem;
      width: 100%;
    }

    .dateCell,
    .completedCell {
      padding-left: 3.75rem;
    }
  }
}
