@import "./components/ToggleSection";
@import "./components/Tenant";

.navbar-toggler-icon {
  background-image: none !important;
  height: initial !important;
  width: initial !important;
}

.top-nav-bar,
.top-nav-bar--links {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
  }
}

.top-nav-bar--links {
  @extend .list-unstyled;
}

.top-nav-bar--links {
  @include media-breakpoint-down(sm) {
    margin: 1em;
  }
}

#header #top-nav-expand-collapse {
  @include media-breakpoint-down(sm) {
    flex: 10 1 100%;
  }
}
