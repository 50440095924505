$fuze-btn-icon-spacing: 0.2rem; // 8px

$fuze-btn-wide-width: 16.25rem; // "260px"

$fuze-btn-disabled-opacity: 0.3;

.btn-icon-left {
  #{$icomoon-selector} {
    line-height: 0;
    margin-right: $fuze-btn-icon-spacing;
    position: relative;
    right: 0.2rem;
    top: -0.1rem;
    vertical-align: middle;
  }
}

.btn-icon-right {
  #{$icomoon-selector} {
    left: 0.2rem;
    line-height: 0;
    margin-left: $fuze-btn-icon-spacing;
    position: relative;
    top: -0.1rem;
    vertical-align: middle;
  }
}

.btn-round {
  border-radius: 100px;
  height: 40px;
  min-width: auto;
  overflow: hidden;
  padding: 10px;
  width: 40px;
  /* icon */
  #{$icomoon-selector} {
    position: relative;
    left: -1px;
  }
}

.btn-wide {
  min-width: $fuze-btn-wide-width;
}

.btn-normal-spacing {
  letter-spacing: normal;
}

.unstyled {
  background: none;
  border: none;
  text-align: left;
}

.header-navigation-button {
  @include button--transparent();
  display: block;
  font-size: $fuze-font-size-base;
  color: $white;
  opacity: 0.7;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  &:hover {
    opacity: 1;
    background: $purple-extra-dark;
    border: 1px solid $purple-extra-dark;
    border-radius: 0.3rem;
  }
}
.header-navigation-button--active {
  display: block;
  font-size: $fuze-font-size-base;
  color: $white;
  opacity: 1;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 0.25rem;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
}
