.image-row {
  display: flex;
  align-items: center;
}

.image-row--responsive {
  @include media-breakpoint-down(md) {
    flex-direction: column;

    .image-row__content {
      margin: 1rem 0 0;
      text-align: center;
    }
  }
}

.image-component {
  flex: 0;
}

.image-row__content {
  flex: 1;
  margin-left: 1rem;
  text-align: left;

  .title {
    font-size: $h2-font-size;
  }
}

.image-row__content {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.image-component {
  line-height: 0;

  & > *:not(i) {
    height: auto;
    width: 1em;
  }

  i {
    font-size: 1em;
  }
}

.image-component--align-vertical-top,
.image-row__content--align-vertical-top {
  align-self: flex-start;
}
