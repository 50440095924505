.formattedDate,
.formattedCompletedBy,
.formattedCost {
  font-size: $fuze-font-size-base;
}

.formattedDate {
  font-weight: normal;
}

.subheading {
  font-size: $fuze-font-size-small;
  font-weight: $fuze-font-weight-regular;
  line-height: 1.125rem;
  color: $gray-700;
}

.formattedSubheadingRegular {
  @extend .subheading;
  font-size: $fuze-font-size-regular;

  .formattedSubheadingRegular__detail + .formattedSubheadingRegular__detail:before {
    content: " · ";
  }
}

.mobileText {
  display: none;
  font-weight: $fuze-font-weight-regular;
  color: $gray-50;
}

@include media-breakpoint-down(md) {
  .formattedDate,
  .formattedCompletedBy {
    display: inline;
    padding-right: 0.5rem;
    font-weight: $fuze-font-weight-light;
  }

  .formattedCompletedBy + .subheading {
    &:before {
      content: "|";
      padding-right: 0.5rem;
      color: $gray-30;
    }
  }

  .formattedCell {
    .subheading {
      display: inline;
      font-size: $fuze-font-size-regular;
      font-weight: $fuze-font-weight-light;
      color: $gray-99;
    }
  }

  .mobileText {
    display: inline;
  }
}

.keyboardTrigger {
  background: none;
  border: 0 none;
  border-radius: 0;
  text-align: left;

  &:focus {
    outline: 0.063rem solid $purple-light;
    outline-offset: 0.625rem;

    @include media-breakpoint-down(md) {
      outline-offset: 0;
    }
  }
}
