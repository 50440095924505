.info-button__anchor {
  display: inline-block;
  width: 1.5rem;
  pointer-events: initial;
  vertical-align: baseline;
  overflow: visible;

  path:first-child {
    fill: $gray-50;
    stroke: $gray-50;
  }
  &:hover path:first-child {
    fill: $teal;
    stroke: $teal;
  }
}

.info-button__text {
  font-size: $font-size-xxs;
  font-weight: $font-weight-base;
  color: $gray-850;
  text-align: center;
}

.info-button__title {
  margin-bottom: 2px;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  text-align: center;
}
