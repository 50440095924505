.statistics-header {
  .statistics-header__subtitle {
    font-size: $fuze-font-size-base;
    font-weight: $fuze-font-weight-light;
    margin-top: -2rem;
    margin-bottom: 2.8rem;
  }

  .statistic-header__link {
    color: white;
    transition: color 0.2s ease;
  }

  .statistic-header__amount {
    font-size: $fuze-font-size-statistic-title;
    font-weight: $fuze-font-weight-light;
    line-height: 3rem;
    animation: fade-in-updated-values 0.7s;
  }
}

.dashboard-statistics {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3.75rem;
  width: 100%;
}

.statistics__icon {
  height: 52px;
  width: 100%;
  max-width: 52px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  margin-right: 1rem;
  text-align: center;
  font-size: $fuze-font-size-large;
  padding-top: 0.5em;
  background-color: rgba(white, 0.25);
  bottom: auto;
  &:before {
    color: white;
  }
}

.statistic__title {
  font-size: $font-size-xxs;
  font-weight: $fuze-font-weight-medium;
  letter-spacing: 0.063rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

@keyframes fade-in-updated-values {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.statistic-header--alloy-migration {
  .icon-migration {
    padding-top: 0.938rem;
  }

  .icon-checklist {
    padding-top: 0.875rem;
  }
}

.statistic-header--company {
  .icon-phone-check {
    padding-top: 0.9rem;
  }

  .statistic-header__link--new-user {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-left: 0.75rem;
    color: white;
    opacity: 0.75;
    border: 1px solid white;
    border-radius: 50%;
    text-align: center;
    font-size: $fuze-font-size-h2-sub;
    transition: color 0.2s ease, border 0.2s ease, opacity 0.2s ease;
    &:hover {
      opacity: 1;
      border: 1px solid $teal-light;
      color: $teal-light;
    }
    i {
      bottom: initial;
    }
  }
}
