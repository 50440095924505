$avatar-size: 3rem;
$avatar-size--small: 2rem;
$avatar-size--large: 6.375rem;

%avatar__overlay--positioning {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.avatar {
  background-color: $purple;
  border-radius: 100%;
  font-size: $font-size-xl;
  font-weight: inherit;
  height: $avatar-size;
  overflow: hidden;
  position: relative;
  min-height: $avatar-size;
  min-width: $avatar-size;
  text-transform: uppercase;
  width: $avatar-size;

  &.inactive {
    background-color: $gray-40;
  }

  &:not(.avatar--with-name) {
    margin-right: 0.75em;
  }
}

.avatar__overlay {
  @extend %avatar__overlay--positioning;

  align-items: center;
  color: white;
  display: flex;
  justify-content: center;

  &.avatar__overlay--locked {
    text-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25), 1px 0px 4px rgba(0, 0, 0, 0.25);

    &:before {
      @include gradient-purple-purple-to-blue();
      @extend %avatar__overlay--positioning;
      content: "";
      opacity: 0.5;
    }
  }
}

.avatar__overlay--image {
  background-size: 100%;
  background-repeat: no-repeat;
}

.avatar__overlay--icon {
  background: inherit;
}

.avatar__icon {
  bottom: initial;
  color: inherit;
  font-size: inherit;

  &:before {
    color: inherit;
  }
}

.avatar--small {
  height: $avatar-size--small;
  width: $avatar-size--small;
  min-height: $avatar-size--small;
  min-width: $avatar-size--small;
  align-self: center;
  font-size: 0.75rem;
}

.avatar--large {
  height: $avatar-size--large;
  width: $avatar-size--large;
  font-size: 3rem;
}

.avatar-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.user__additional-details {
  font-size: $fuze-font-size-small;
  font-weight: 400;
  color: $fuze-gray-70;
}

.avatar-with-name-container {
  .image-row__content {
    line-height: 1.25;
  }
}
