@import "src/styles/bootstrap_standin/_boostrap_standin.scss";

.openButton {
  width: 44px;
  height: 40px;
  position: absolute;
  border: 0 none;
  background: transparent;
  position: absolute;
  z-index: 2;

  &:focus {
    outline: none;

    & + .searchWrapper:before {
      box-shadow: $btn-focus-box-shadow;
    }
  }

  i {
    position: static;
  }
}

div.searchWrapper {
  bottom: initial;
}

.expandableSearch {
  float: right;
  width: auto;

  input[type="text"] {
    width: 44px;
    padding-right: 0;
    padding-left: 42px;
  }
}

.isExpanded {
  width: 200px;

  input[type="text"] {
    width: 100%;
    padding-right: 24px;
    padding-left: 40px;
  }
}

.searchContainer {
  padding-right: 0.5rem;
  flex: 1;
  max-width: 300px;
}
