.btn-transparent--vertical + .dropdown-menu {
  transform: translate3d(-28ch, 1.5rem, 0) !important;
}

// bug fix: current reactstrap doesn't show dropdown on clicking toggle (!?)
.dropdown.show > .dropdown-menu {
  display: block;
}

// new: like dropdown-item with padding, but just a container with no :hover :focus etc.
.dropdown {
  .dropdown-block {
    padding: 0.25rem 1.5rem;
  }
}

// wrap <Dropdown> with class="dropdown-menu-right" to right-align the menu with the toggle
.dropdown.dropdown-menu-right > .dropdown-menu {
  left: auto;
  right: 0;
}

.dropdown-menu {
  padding: 0;
}

.dropdown-menu-left-up {
  top: -64px !important; // override inline style, as there is no left-up for Reactstrap dropdowns
  min-width: 170px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border: 0 none;
}
