#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;

  //  This is a fix for library components and using react portals.
  //  We simply cannot trust that they will have a z-index that makes sense,
  //  so we inform them to place themselves nicely, first come first served.
  @for $i from 1 through 50 {
    & ~ div:nth-child(#{$i}) {
      z-index: #{1000 + $i} !important;
    }
  }
}

// universal padding for each <section> and other section-like blocks
.section-block {
  $negative-margin-children: ".react-tabs, .section-block__inlay, .footer, .section-cutout, .breakout-within-instruction, .instruction-buttons";

  padding: $sectionBlockPaddingTop $sectionBlockPaddingRight $sectionBlockPaddingBottom $sectionBlockPaddingLeft;
  .react-tabs {
    width: initial;
  }
  .grey-page-header {
    margin-top: -$sectionBlockPaddingTop;
  }

  #{$negative-margin-children} {
    margin-left: -$sectionBlockPaddingLeft;
    margin-right: -$sectionBlockPaddingRight;
    padding-left: $sectionBlockPaddingLeft;
    padding-right: $sectionBlockPaddingRight;

    .section-block__row,
    .react-tabs__tab-list {
      margin-left: -$sectionBlockPaddingLeft;
      margin-right: -$sectionBlockPaddingRight;
      padding-left: $sectionBlockPaddingLeft;
      padding-right: $sectionBlockPaddingRight;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: $sectionBlockPaddingHorizontal-sm;

    .grey-page-header {
      margin-top: -$sectionBlockPaddingHorizontal-sm;
    }

    #{$negative-margin-children} {
      margin-left: -$sectionBlockPaddingHorizontal-sm;
      margin-right: -$sectionBlockPaddingHorizontal-sm;
      padding-left: $sectionBlockPaddingHorizontal-sm;
      padding-right: $sectionBlockPaddingHorizontal-sm;

      .section-block__row,
      .react-tabs__tab-list {
        margin-left: -$sectionBlockPaddingHorizontal-sm;
        margin-right: -$sectionBlockPaddingHorizontal-sm;
      }
    }
  }

  .footer {
    border-top: 1px solid $gray-200;
    padding-top: 2rem;

    input,
    label {
      margin-bottom: 1rem;
    }

    button {
      min-width: $fuze-btn-wide-width;
    }
  }

  .section-gradient + & {
    padding-top: 0;

    .react-tabs__tab-list {
      background-image: linear-gradient($gray-5, $gray-10);
    }
  }
}

.page--grey,
.section-block--grey {
  background-color: $fuze-gray-3;
}

.page--grey {
  .fuze-tabs__tab-list {
    background-image: linear-gradient($gray-5, $gray-10);
  }
}

.section-block--grey {
  flex: 1;
}

// page layout for sidebar and content
.content-layout {
  display: flex;
  flex: 1;
  max-width: inherit;

  // default 200px wide
  // phone: display none
  .col-sidebar {
    background-color: $gray-050;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    flex: 0 0 200px;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .col-page-content {
    flex: 1;
  }

  // page's content
  // default max width 1000px wide
  // tablet and phone: 100% wide
  .content-container {
    max-width: 1200px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }
}

.section-block > .react-tabs {
  ul.react-tabs__tab-list {
    list-style: none;
    box-shadow: inset 0 -1px 0 0 #e9eaeb;
    li[role="tab"] {
      color: $gray-60;
      font-size: $font-size-sm;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 15px;
      padding: 0 1rem;
      margin: 0 2rem 0 0;
      text-align: center;
      cursor: pointer;

      &[aria-selected="true"] {
        color: $purple;
        position: relative;

        /**
         * We're overriding the default styling so that we can use
         * the `::after` like we do for our tabs in hub-style
         */
        &::before {
          content: initial;
        }

        /**
         * TODO:
         * This is a direct copy paste from hub-style
         * .fuze/components/_navigation-tabs.scss
         * We need to make this an extensions so that it can be
         * re-used here.
         */
        &::after {
          content: "";
          position: absolute;
          right: 0;
          left: 0;
          bottom: -1px;
          width: 90%;
          height: 3px;
          margin: auto;
          border-radius: 3px;
          z-index: 1;
          background-color: $fuze-purple-primary;
        }
      }
      a {
        color: $gray-60;

        &.active {
          color: $purple;
        }
      }
    }
  }

  .react-tabs__tab-list {
    background-color: $gray-3;
  }

  .tab-contents {
    background-color: white;
  }
}

@mixin single-column-layout {
  grid-auto-flow: initial !important;
  grid-template-columns: 100% !important;
  grid-template-rows: initial !important;

  .grid__column {
    padding: 0 !important;
  }

  .fake-panel--secondary {
    display: none;
  }
}

.grid {
  $columns: 2fr 1.25fr;

  display: grid;
  grid-gap: 1rem;
  grid-template-columns: $columns;

  &.grid--2-columns {
    grid-template-columns: 50% 50%;
  }

  @include media-breakpoint-down(lg) {
    @include single-column-layout;
  }

  .grid__title {
    grid-column: auto / span 2;
    margin-bottom: 1rem;
  }

  .image-row .title {
    font-size: 1rem;
    font-weight: $font-weight-bold;
  }

  .image-row__content {
    line-height: 1.5;
  }

  .grid__column {
    display: grid;
    grid-template-rows: 350px 350px;
    max-width: 99%; // necessary hack to make recharts responsive

    .panel {
      display: flex;
      flex-direction: column;
    }

    .panel__list-item {
      flex: 1;
    }

    .panel__header {
      flex: 0 auto;
    }

    .panel__inset {
      height: 100%;
    }
  }

  .grid__column--3-rows {
    grid-template-rows: 350px 350px 600px;
  }
}
