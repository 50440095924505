.Toastify__toast-container {
  max-width: 450px;
  width: 100%;
  top: 0;
  right: 0;
  padding: 10px;
}

.Toastify__toast {
  padding: 0.5em 1em;
  color: $gray-99;
  background-color: $gray-3;
  border-radius: 4px;
  font-family: $default-font-family;
  box-shadow: 0 1px 10px 0 rgba(black, 0.1);
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px rgba(black, 0.6);
  }
}

.toast__icon {
  margin: auto 0.7em;
  width: 60px;
  height: 60px;
}

.toast__container {
  display: flex;
}

.toast__title {
  color: $gray-99;
  font-size: $h4-font-size;
  font-weight: $fuze-font-weight-light;
  margin-bottom: 5px;
}

.toast__content {
  color: $gray-60;
  line-height: 21px;
  font-size: $h6-font-size;
  font-family: $font-family-sans-serif;
  padding: 1em 0 1em 1em;
  word-break: break-word;
}

.Toastify__close-button > svg {
  width: 22px;
  height: 22px;
}
