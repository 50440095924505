.form-field-validation,
.custom-control {
  margin-bottom: 1rem;
}

.form-field-validation .custom-control {
  margin-bottom: 0 !important;
}

.custom-control {
  margin-bottom: 1rem;
}

.form-field-validation {
  align-items: center;
  display: flex;
  position: relative;

  & > * {
    &:not(.custom-radio):not(.form-field-validation__icon) {
      flex: 1;
    }
  }

  &.error {
    border-color: $red;

    .react-select [class*="__control"],
    .fuze-select [class*="__control"],
    .react-tagsinput,
    .form-control {
      border-color: $red;
    }
  }
}

.form-field-validation__icon {
  $icon-height: 1em;

  background-repeat: no-repeat;
  background-size: 100%;
  flex: 0 0 $icon-height;
  height: $icon-height;
  margin-left: 0.5em;

  .did-selector + & {
    align-self: flex-end;
    margin-bottom: 0.75em;
  }

  .error & {
    background-image: url("../../images/error_icon_round.png");
  }

  .success & {
    background-image: url("../../images/confirmation_icon.png");
  }
}

.validation-state-tooltip {
  &.tooltip.show {
    opacity: 1;
  }
  .tooltip-inner {
    background-color: $red !important;
    color: white !important;
    font-family: $font-family-sans-serif;
    font-size: $font-size-sm;
    padding: 9px 12px !important;
    text-align: left;
  }
  .arrow::before {
    border-right-color: $red !important;
  }
}

.react-select [class*="__control"] {
  .validation-state.error & {
    border-color: $red;
  }
}

.react-tagsinput {
  .validation-state.error & {
    border-color: $red;
  }
}
