.error-badge__tooltip {
  background: $danger !important;
  color: white !important;
}
.bs-tooltip-bottom .error-badge__tooltip-arrow::before {
  border-bottom-color: $danger !important;
}
.bs-tooltip-right .error-badge__tooltip-arrow::before {
  border-right-color: $danger;
  border-bottom-color: transparent;
}
.error-badge {
  line-height: initial;
}
